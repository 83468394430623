import { useEffect } from 'react';
import { useRouter } from 'next/router';
import process from "process";

// ----------------------------------------------------------------------

export default function Index() {
  const router = useRouter();

  useEffect(() => {
    const path = window.location.pathname;
    if (router.pathname === '/') {
      // 影響範囲が不確かなので、/test, /previewの時だけの処理とする
      const hasSubDir = router.basePath === '/test' || router.basePath === '/preview';
      
      if (!hasSubDir) {
        router.push(`${path}`);
      } else {
        router.push(path.replace(new RegExp(`^${router.basePath}`), ''));
      }
    }
  });

  return <></>;
}
